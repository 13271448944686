<template>
  <div>
    <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination b-pagination b-pagination-pills flex-wrap justify-content-center my-2">
      <li role="presentation" class="page-item" v-for="(n, i) in itemsCount" :key="`item_no_${n}`" :class="{active: isActive(n), 'ml-1': (n > 1)}">
        <button role="menuitemradio" type="button" aria-label="`Go to item ${n}`" :aria-checked="(isActive(n) ? 'true' : 'false')" :aria-posinset="n"
          :aria-setsize="itemsCount" :tabindex="i" class="page-link" @click="setItem(n)" :class="getHighlightClass(n)">{{n}}</button>
      </li>
    </ul>
    <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination b-pagination b-pagination-pills flex-wrap justify-content-center my-2" v-if="showPrevNext">
      <li role="presentation" class="page-item mr-auto" :class="{disabled: isFirst}" :aria-hidden="(isFirst ? 'true' : 'false')">
        <span role="menuitem" aria-label="Go to previous item" aria-disabled="true" class="page-link mr-auto" v-if="isFirst">
          <span class="mr-sm-5"><span class="mr-2">‹</span>Previous</span>
        </span>
        <button role="menuitem" type="button" tabindex="-1" aria-label="Go to previous item" @click="goPrevious" class="page-link mr-auto" v-else>
          <span class="mr-sm-5"><span class="mr-2">‹</span>Previous</span>
        </button>
      </li>
      <li role="presentation" class="page-item ml-auto" :class="{disabled: isLast}" :aria-hidden="(isLast ? 'true' : 'false')">
        <span role="menuitem" aria-label="Go to next item" aria-disabled="true" class="page-link ml-auto" v-if="isLast">
          <span class="ml-sm-5">Next<span class="ml-2">›</span></span>
        </span>
        <button role="menuitem" type="button" tabindex="-1" aria-label="Go to next item" @click="goNext" class="page-link ml-auto" v-else>
          <span class="ml-sm-5">Next<span class="ml-2">›</span></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    value: { type: Number, default: 1 },
    itemsCount: { type: Number, default: 0 },
    showPrevNext: { type: Boolean, default: true },
    highlightActive: { type: Boolean, default: true },
    greenItems: {
      type: Array,
      default() { return []; },
    },
    redItems: {
      type: Array,
      default() { return []; },
    },
    yellowItems: {
      type: Array,
      default() { return []; },
    },
  },

  data() {
    return {
      currentItem: this.value,
    };
  },

  watch: {
    value: function valueChanged(newVal) {
      this.currentItem = newVal;
    },
  },

  computed: {
    isFirst() {
      return this.currentItem === 1;
    },
    isLast() {
      return this.currentItem === this.itemsCount;
    },
  },

  methods: {
    setItem(val) {
      if (val > 0 && val <= this.itemsCount) {
        this.currentItem = val;
        this.$emit('input', this.currentItem);
      }
    },
    goPrevious() {
      return this.setItem(this.currentItem - 1);
    },
    goNext() {
      return this.setItem(this.currentItem + 1);
    },
    isActive(val) {
      return this.highlightActive && this.currentItem === val;
    },
    isGreen(val) {
      return this.greenItems.indexOf(val) >= 0;
    },
    isRed(val) {
      return this.redItems.indexOf(val) >= 0;
    },
    isYellow(val) {
      return this.yellowItems.indexOf(val) >= 0;
    },
    getHighlightClass(val) {
      const bActive = this.isActive(val);
      const bGreen = this.isGreen(val);
      const bRed = this.isRed(val);
      const bYellow = this.isYellow(val);
      return {
        'border-success': bGreen,
        'border-danger': bRed,
        'border-warning': bYellow,
        'bg-success': bActive && bGreen,
        'bg-danger': bActive && bRed,
        'bg-warning': bActive && bYellow,
        'text-success': !bActive && bGreen,
        'text-danger': !bActive && bRed,
        'text-warning': !bActive && bYellow,
      };
    },
  },
};
</script>
